<template>
    <div id='my-logo' @click.stop ='rotateGlasses'>
        <svg 
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        class="my-logo"
        width="55"
        height="55"
        viewBox="0 0 843.000000 781.000000"
        preserveAspectRatio="xMidYMid meet">
            <g class="my-logo_g" transform="translate(0.000000,781.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
                <path class="my-logo_base" d="M3490 7096 c0 -8 22 -48 49 -88 105 -159 161 -297 161 -399 0 -72
                -19 -79 -71 -29 -53 51 -142 104 -221 131 -64 21 -214 52 -229 47 -6 -2 18
                -21 53 -43 96 -61 194 -159 244 -244 24 -42 44 -81 44 -88 0 -6 -26 -20 -57
                -29 -558 -163 -914 -366 -1288 -737 -275 -272 -436 -509 -619 -909 -19 -43
                -40 -78 -46 -78 -6 0 -37 14 -70 30 -221 112 -417 118 -617 19 -241 -119 -399
                -372 -423 -679 -22 -277 34 -514 168 -719 114 -174 286 -310 457 -363 60 -18
                176 -25 282 -18 118 9 128 6 138 -41 16 -73 96 -303 138 -396 141 -312 329
                -584 572 -828 137 -137 159 -158 289 -259 252 -197 635 -393 911 -466 101 -27
                311 -80 318 -80 14 0 7 18 -10 24 -10 3 -43 20 -73 37 -282 161 -482 399 -576
                685 -88 270 -85 527 7 782 18 48 32 100 33 117 l1 30 -65 -3 c-36 -2 -119 4
                -185 12 -109 14 -144 22 -256 55 -57 17 -219 103 -295 156 -38 27 -110 90
                -159 140 -330 332 -439 804 -289 1252 57 170 118 277 234 408 332 376 868 512
                1335 338 140 -52 289 -143 400 -244 111 -102 261 -322 301 -441 l16 -48 58 0
                58 0 51 105 c65 137 135 237 240 344 357 361 917 461 1378 246 442 -207 702
                -626 703 -1133 0 -285 -119 -575 -331 -806 -228 -248 -569 -394 -903 -385 -67
                2 -82 -1 -84 -14 -2 -8 15 -67 37 -129 60 -171 71 -232 71 -401 0 -160 -9
                -212 -63 -372 -63 -187 -163 -349 -299 -482 -103 -101 -197 -169 -331 -237
                -20 -11 -37 -23 -37 -28 0 -14 0 -14 225 43 254 64 514 173 741 310 513 310
                919 773 1149 1314 50 117 109 289 120 348 11 55 20 59 121 47 243 -27 441 41
                619 215 150 146 237 305 292 535 25 104 24 386 -1 480 -93 348 -312 568 -610
                612 -139 20 -263 -5 -418 -83 -33 -17 -65 -28 -72 -26 -7 3 -25 36 -41 73 -48
                117 -164 333 -240 449 -224 342 -530 644 -867 854 -234 146 -508 265 -783 340
                -22 6 -49 17 -59 24 -18 13 -18 15 -1 48 29 55 118 171 164 212 23 21 68 55
                99 75 82 54 82 54 22 47 -70 -8 -190 -41 -259 -71 -50 -22 -117 -70 -164 -117
                -47 -47 -70 15 -47 123 14 67 93 231 155 323 39 58 55 97 41 97 -10 0 -148
                -77 -210 -118 -113 -75 -261 -229 -354 -371 -45 -68 -82 -128 -82 -132 0 -5
                -8 -9 -17 -9 -11 0 -28 18 -42 43 -141 248 -313 418 -563 555 -66 37 -68 37
                -68 18z m-2278 -2765 c65 -28 168 -108 168 -130 0 -11 -7 -55 -16 -98 -56
                -284 -56 -404 -2 -662 l22 -103 -22 -19 c-30 -24 -125 -43 -184 -36 -162 20
                -290 153 -374 392 -26 73 -28 92 -28 215 0 123 3 142 28 210 32 84 63 137 103
                175 34 32 92 71 119 78 34 10 142 -3 186 -22z m6119 8 c88 -50 138 -113 186
                -234 25 -65 27 -81 27 -210 0 -163 -11 -213 -82 -347 -73 -140 -178 -234 -288
                -259 -65 -15 -181 2 -216 30 l-21 18 17 124 c19 139 23 355 7 505 -18 176 -22
                249 -14 260 21 30 169 113 228 128 34 8 131 -1 156 -15z" fill="var(--primary)"/>
                <path class="my-logo_glasses" d="M2650 4270 c-169 -14 -333 -29 -420 -39 -189 -22 -338 -33 -358 -27
                -18 6 -23 -1 -42 -51 -23 -63 -55 -190 -66 -265 -5 -41 -4 -49 12 -54 58 -18
                72 -28 96 -65 15 -23 29 -59 33 -82 35 -238 59 -350 104 -484 72 -216 154
                -337 294 -430 63 -43 103 -59 242 -103 68 -21 233 -40 347 -40 231 0 419 52
                562 154 147 105 276 270 379 487 60 125 117 261 117 280 0 22 62 137 86 160
                19 17 41 24 101 29 87 7 116 1 154 -36 28 -27 34 -39 99 -209 110 -286 214
                -460 365 -610 111 -111 173 -152 292 -194 173 -61 385 -75 617 -42 445 65 634
                307 736 941 28 168 47 208 114 235 l39 16 -6 67 c-7 75 -36 196 -61 255 -15
                33 -20 37 -54 37 -20 0 -84 5 -142 10 -255 25 -412 41 -505 52 -136 16 -602
                16 -685 1 -36 -7 -94 -17 -130 -23 -71 -13 -108 -20 -215 -43 -38 -8 -119 -24
                -180 -36 -60 -12 -128 -27 -150 -32 -22 -6 -124 -13 -228 -16 -207 -6 -285 5
                -672 92 -33 7 -98 20 -145 29 -47 8 -112 20 -145 26 -75 14 -458 21 -585 10z
                m625 -89 c199 -41 292 -76 387 -144 80 -58 131 -130 161 -228 21 -69 21 -96 2
                -201 -58 -308 -149 -501 -311 -660 -101 -99 -199 -156 -339 -198 -218 -66
                -538 -41 -745 58 -180 86 -295 239 -340 451 -35 165 -25 439 21 591 14 48 57
                130 82 158 54 59 177 116 312 145 196 42 246 46 480 43 130 -2 256 -8 290 -15z
                m2415 -1 c323 -59 448 -132 510 -297 34 -91 53 -224 53 -383 2 -266 -56 -432
                -198 -575 -83 -83 -189 -141 -325 -176 -61 -15 -244 -39 -305 -39 -139 0 -297
                35 -420 95 -268 130 -436 395 -510 805 -21 118 -12 182 42 288 41 83 94 135
                181 180 82 42 235 92 285 93 18 0 59 7 92 14 73 18 493 14 595 -5z" fill="var(--fill-svg-glasses)"/>
                <path d="M2967 3884 c-4 -4 -4 -39 -1 -78 6 -69 5 -72 -29 -123 -30 -44 -33
                -53 -18 -53 56 0 111 -48 111 -98 0 -33 -53 -82 -89 -82 -83 0 -118 58 -85
                143 4 12 -3 14 -40 10 -50 -6 -93 7 -143 44 -17 12 -38 23 -46 23 -23 0 -47
                -90 -47 -173 0 -226 164 -387 394 -387 157 0 280 76 350 217 89 178 29 392
                -138 498 -80 50 -196 82 -219 59z" fill="var(--fill-svg-eyes)"/>
                <path d="M5223 3871 c-227 -82 -331 -335 -226 -549 65 -132 198 -212 353 -212
                161 0 290 82 358 228 37 79 44 224 13 295 -23 52 -30 56 -55 28 -10 -11 -39
                -29 -64 -41 -37 -18 -56 -21 -95 -16 -45 6 -49 5 -42 -11 34 -81 -3 -143 -86
                -143 -34 0 -75 35 -84 73 -14 53 37 107 101 107 l25 0 -26 28 c-38 40 -60 122
                -45 168 7 19 9 41 6 49 -8 21 -69 19 -133 -4z" fill="var(--fill-svg-eyes)"/>
                <path d="M3700 2446 c-61 -17 -112 -45 -105 -56 4 -6 30 -10 58 -10 109 0 198
                -46 288 -150 44 -51 57 -60 85 -60 70 0 94 69 50 139 -73 114 -238 175 -376
                137z" fill="var(--fill-svg-face)"/>
                <path d="M4440 2446 c-112 -31 -220 -131 -220 -204 0 -40 33 -72 76 -72 36 0
                38 1 112 89 66 78 157 121 259 121 63 0 75 13 31 36 -81 42 -175 53 -258 30z" fill="var(--fill-svg-face)"/>
                <path d="M3310 1539 c1 -53 116 -205 218 -288 104 -84 243 -151 397 -191 84
                -22 339 -25 437 -5 228 46 414 151 547 309 52 62 115 172 106 187 -4 5 -30
                -10 -58 -34 -188 -159 -418 -246 -700 -267 -341 -25 -665 72 -894 268 -50 42
                -53 43 -53 21z" fill="var(--fill-svg-face)"/>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'my-logo',
        methods: {
            rotateGlasses (e) {
                e.target.classList.add("active");
                setTimeout(() => (e.target.classList.remove("active")), 500)
            }
        }
    }
</script>

<style lang="css" scoped>
#my-logo {
    position: relative;
    margin-left: 20px;
}

#my-logo::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#my-logo.active .my-logo_glasses {
    transform-origin:50% 50%;
    transform-box: fill-box;
    animation: rotateBox .5s linear;
}

@keyframes rotateBox {
    to {
        transform: rotate(360deg);
    }
}
</style>
